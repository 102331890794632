* {
  font-family: "europa", sans-serif;
}

/* The overall container for the two sides of this element */
.contact_me_container {
  display: flex;
  background-color: #0f52ba;
  justify-content: center;
  align-items: center;
  gap: 30px;
  height: auto;
  min-height: 30vh;
  padding: 40px 20px;
  margin-bottom: 20vh;
}

/* The text only portion of the component */
.contact_me_text {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 35vw;
  max-width: 500px;
}

#header {
  font-size: 2em;
  color: white;
  margin-bottom: 10px;
}

#subtext {
  font-size: 20px;
  color: white;
  line-height: 25px;
}

/* The entire input container include message and submit button */
.contact-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 20px 0 20px 0;
}

/* The identifiers of the form: name and email */
.contact_me_identifiers {
  display: flex;
  justify-content: center;
  gap: 10px;
}

#name,
#email {
  border: none;
  border-bottom: 2px solid white;
  background-color: transparent;
  color: white;
  outline: none;
  font-size: 18px;
  padding: 5px;
  width: 100%;
  min-width: 200px;
}

/* Message and submit button */
#message {
  border: none;
  border-bottom: 2px solid white;
  background-color: transparent;
  color: white;
  outline: none;
  font-size: 18px;
  resize: none;
  height: 100px;
  padding: 5px;
  width: 100%;
}

input::placeholder,
textarea::placeholder {
  font-size: 18px; /* Match the font size of the placeholder text */
  color: #d3d3d3;
}

#submit_button {
  border: 2px solid white;
  border-radius: 10px;
  background-color: transparent;
  color: white;
  font-size: 20px;
  font-weight: bold;
  padding: 10px;
  cursor: pointer; /* Add pointer cursor */
}

#submit_button:disabled {
  cursor: not-allowed; /* Disable cursor when sending */
  opacity: 0.6; /* Greyed-out effect */
}

.success-message h2 {
  color: white;
  font-size: 2em;
  margin-bottom: 10px;
}

.success-message p {
  color: white;
  font-size: 18px;
}

/* Responsive Design for smaller screens */
@media (max-width: 768px) {
  .contact_me_container {
    flex-direction: column;
    height: auto;
    
  }

  .contact_me_text {
    width: 90vw;
  }
  .contact-form {
    width: 75vw;
  }

  .contact_me_identifiers {
    flex-direction: column;
    gap: 10px;
  }

  #name,
  #email,
  #message,
  #submit_button {
    width: 90%; /* Make input fields take up more space */
    margin: 0 auto 20px auto; /* Center the input fields */
  }
}

/* Autofill style */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #6e07f3 inset;
  caret-color: white;
  -webkit-text-fill-color: #fff;
}
