/* Navbar.css */
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;600&display=swap");

nav {
  padding: 3vh 10vw 3vh 10vw;
  background-color: #ecf0f1;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

li {
  display: inline;
  margin: 0 15px;
}

nav a {
  text-decoration: none;
  color: #333333; /* Dark Text */
  font-family: "Roboto", sans-serif; /* Applying Roboto font */
  font-weight: 800; /* 600 weight */
}

.left-container li a {
  font-size: 2rem;
  line-height: 2.25rem;
}

.right-container li a {
  padding-left: 2vw;
  font-size: 1.875rem;
  line-height: 2.25rem;
}

a:hover {
  color: #5dade2; /* Light Teal for hover */
}

a.active {
  color: #b0b0b0; /* Dark Gray for active link */
}

@media (max-width: 768px) {
  nav {
    padding: 2vh 5vw;
  }
 
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
 
  .left-container img {
    width: 50px;
    height: 50px;
  }
 
  .right-container li a {
    font-size: 1.5rem;
    padding-left: 1.5rem;
  }
 }