.slider-card {
  width: 100%;
  max-width: 18vw;
  text-align: center;
  padding: 10px;
  background-color: #cacfd2;
  box-shadow: 2px 2px 10px rgba(160, 120, 200, 0.2);
  border: 2px solid #e0e0e0; /* Light gray border */
  border-radius: 8px; /* Slightly rounded corners */
}

.slider-wrapper {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.slider-track {
  display: flex;
  transition: transform 0.5s ease-in-out;
  /* Controls the smoothness of the sliding effect */
}

.slider-image {
  width: 100%;
  height: auto;
  flex-shrink: 0; /* Prevent the image from shrinking */
}

.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-20%);
  background: radial-gradient(
    circle,
    rgba(0, 0, 0, 0.07) 30%,
    rgba(0, 0, 0, 0) 80%
  );
  color: white;
  border: none;
  cursor: pointer;
  z-index: 2;
  font-size: 18px;
  width: 60px;
  height: 60px;
  display: flex;
  padding: 0;
  justify-content: center;
  align-items: center;
}

.arrow:active {
  background: radial-gradient(
    circle,
    rgba(0, 0, 0, 0.15) 30%,
    rgba(0, 0, 0, 0) 80%
  );
}

.left-arrow {
  left: 0px;
}

.right-arrow {
  right: 0px;
}

.slider-title {
  font-size: 24px;
  font-family: "eurostile", sans-serif;
  margin: 20px 0 10px 5px;
  font-weight: bold;
  width: 100%;
  text-align: left;
}

.slider-description {
  font-size: 16px;
  font-family: "eurostile", sans-serif;
  color: black;
  margin: 5px 0 15px 5px;
  width: 100%;
  text-align: left;
}

.slider-action {
  background-color: transparent;
  border: 3px solid purple;
  color: purple; /* Purple text */
  padding: 10px 20px; /* Some padding for the button */
  font-size: 16px; /* Font size for readability */
  font-weight: lighter;
  cursor: pointer;
  margin: 5px auto 10px auto; /* Add space between description and button */
}
