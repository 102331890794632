/* Hero CSS */
.hero {
  display: flex;
  padding: 5vw 15vw;
  justify-content: center;
  max-width: 1200px;
  margin: 0 auto;
  align-items: center;
}

.container2 {
  flex: 1;
  min-width: 450px; /* Prevent text from getting too narrow */
  max-width: 600px;
}

img {
  width: 100%;
  max-width: 500px;
  height: auto;
  object-fit: contain;
}

.header-text {
  margin: 0 0 20px 0;
  color: #d35400;
  font-size: 44px;
  font-family: "europa", sans-serif;
}

.title-text {
  margin: 0 0 20px 0;
  color: #2980b9;
  font-size: 44px;
  font-family: "europa", sans-serif;
}

.body-text {
  margin-bottom: 10px;
  color: #7f8c8d;
  font-size: 22px;
  font-family: "europa", sans-serif;
}

/* Media Query for smaller screens */
@media (max-width: 768px) {
  .hero {
    flex-direction: column-reverse;
    padding: 5vw;
    gap: 1rem;
    align-items: center; /* Center align items */
  }

  .container2 {
    padding-left: 0;
    text-align: center;
    max-width: 500px; /* Match image width */
    min-width: unset; /* Remove min-width constraint */
    width: 100%; /* Take full width up to max-width */
  }

  .body-text {
    max-width: 65ch; /* Limit line length for readability */
    margin: 0 auto; /* Center the text block */
  }
}
