/* Import the required fonts */
@import "~@fortawesome/fontawesome-free/css/all.css";

/* footer.css */
footer {
  background-color: #0a142f;
  color: #fff;
  text-align: center;
}

.footer-content {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.footer-links {
  padding: 10vh 0 2vh 0;
  margin-bottom: 10px;
}

.footer-links a {
  color: #fff;
  padding: 20px;
  text-decoration: none;
}

.footer-links a:hover {
  text-decoration: underline;
}

.social-media {
  padding: 10px 10px 20px 10px;
}

.social-media a {
  color: #fff;
  margin: 0 10px;
  font-size: 1.5em;
  text-decoration: none;
}

/* Social Media Hover colors */

.social-media a:nth-child(1):hover {
  color: #0077b5; /* LinkedIn Blue */
}

.social-media a:nth-child(2):hover {
  color: #333333; /* GitHub Gray */
}

.social-media a:nth-child(3):hover {
  color: #1da1f2; /* Twitter Blue */
}

.social-media a:nth-child(4):hover {
  color: #e4405f; /* Instagram Pink/Red */
}

.copyright {
  margin-bottom: 0;
}
