.projects-container {
  max-width: 70vw;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: -7.5vw auto 15vw auto; /* Center the container with auto margins */
  gap: 4vw;
}

.projects-text-cont {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5vh 20px 25vh 20px;
  background-color: #0f52ba;
}

.titleText {
  font-size: 2em;
  font-weight: 600;
  font-family: "eurostile", sans-serif;
  color: white;
  text-align: center;
  max-width: 75vw;
  -webkit-font-smoothing: antialiased;
}

.bodyText {
  font-size: 20px;
  font-family: "europa", sans-serif;
  line-height: 1.5;
  font-weight: 400;
  color: white;
  text-align: center;
  max-width: 60vw;
  -webkit-font-smoothing: antialiased;
}
